import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet'; 
import { fetchProjectById } from '../api';
import styled from 'styled-components';

const ProjectContainer = styled.div`
  padding: 20px;
  padding-top: 120px; /* Add padding to offset fixed navbar height */
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const ProjectImage = styled.img`
  width: 90%; /* Match the width with ProjectContent */
  max-width: 800px;
  height: auto;
  margin-bottom: 20px;
  border-radius: 10px;
  margin-top: 20px; /* Add margin to separate the image from the navbar */
  box-sizing: border-box; /* Ensure padding and border are included in the width */
`;

const ProjectContent = styled.div`
  width: 90%; /* Match the width with ProjectImage */
  max-width: 800px; /* Ensure the same max-width */
  background-color: rgba(31, 31, 31, 0.8);
  border-radius: 10px;
  padding: 20px;
  text-align: center;
  margin-bottom: 20px;
  box-sizing: border-box; /* Ensure padding and border are included in the width */
`;

const ProjectTitle = styled.h2`
  margin-top: 0;
`;

const ProjectDescription = styled.p`
  margin-bottom: 10px;
`;

const ProjectDetails = styled.div`
  text-align: left; /* Adjust text alignment */
`;

const CloseButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ffcc00;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 50px;
  cursor: pointer;
  z-index: 1000;

  &:hover {
    background-color: #e6b800;
  }
`;

const Project = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [project, setProject] = useState(null);

  useEffect(() => {
    const getProject = async () => {
      const projectData = await fetchProjectById(id);
      setProject(projectData);
    };

    getProject();
  }, [id]);

  if (!project) {
    return <p style={{ color: 'white' }}>Loading...</p>;
  }

  return (
    <ProjectContainer>

      <Helmet>
        <title>Impossible Creatures - Creating programs and Tools</title>
        <meta name="description" content="Impossible Creatures is a showcase of experimental tools and software created with Chat GPT." />
        <meta name="keywords" content="impossible creatures, AI, artificial intelligence, Chat GPT, Mid Journey, software developer, plugins, addons, blender" />
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content="Impossible Creatures" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.impossiblecreatures.com/branding/SocialMedia/OG_1200x630_002.png" />
        <meta property="og:url" content="https://www.impossiblecreatures.com" />
        <meta property="og:description" content="Explore revolutionary AI-driven artwork and technological advancements with Impossible Creatures." />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@impossiblecrits" />
        <meta name="twitter:title" content="Impossible Creatures" />
        <meta name="twitter:description" content="Augmenting the human condition with AI exploration." />
        <meta name="twitter:image" content="https://www.impossiblecreatures.com/branding/SocialMedia/Twitter_1200x628_002.png" />
        <link rel="canonical" href="https://www.impossiblecreatures.com/project" />
      </Helmet>

      {project.image && <ProjectImage src={project.image} alt={project.title} />}
      <ProjectContent>
        <ProjectTitle>{project.title}</ProjectTitle>
        <ProjectDescription>{project.description}</ProjectDescription>
        <ProjectDetails dangerouslySetInnerHTML={{ __html: project.details }} />
      </ProjectContent>
      <CloseButton onClick={() => navigate('/')}>Close</CloseButton>
    </ProjectContainer>
  );
};

export default Project;
