// src/components/Login.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { signInWithEmailAndPassword } from 'firebase/auth';
import { auth } from '../firebase/firebaseConfig';
import styled from 'styled-components';

const LoginContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 100px);
  background-color: #111;
  padding-top: 100px;
`;

const LoginForm = styled.div`
  padding: 30px;
  border-radius: 8px;
  background: #555;
  box-shadow: 0 6px 12px rgba(0,0,0,0.3);
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const Input = styled.input`
  width: 80%;
  height: 40px;
  margin-bottom: 20px;
  border: 1px solid #ccc;
  border-radius: 4px;
  padding: 0 10px;
`;

const Button = styled.button`
  width: 80px;
  background-color: #f0ad4e;
  color: #333;
  border: none;
  border-radius: 4px;
  height: 40px;
  cursor: pointer;
  &:hover {
    background-color: #ec971f;
  }
  align-self: center;
`;

const FeedbackMessage = styled.div`
  color: #ff6b6b;
  margin-top: 10px;
`;

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [feedback, setFeedback] = useState('');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();

  const handleLogin = async () => {
    if (!email || !password) {
      setFeedback('Please enter both email and password.');
      return;
    }
    setLoading(true);
    setFeedback('Attempting to log in...');
    console.log('Starting login process...');
    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      console.log('Firebase auth successful, user:', userCredential.user);
      setFeedback('Login successful! Redirecting...');
      setTimeout(() => {
        navigate('/admin');
        console.log('Redirecting to /admin...');
      }, 1000);
    } catch (error) {
      console.error('Login error:', error);
      if (error.code === 'auth/wrong-password') {
        setFeedback('Incorrect password, please try again.');
      } else if (error.code === 'auth/user-not-found') {
        setFeedback('No user associated with this email.');
      } else {
        setFeedback('Login failed: ' + error.message);
      }
    } finally {
      setLoading(false);
      console.log('Login process completed.');
    }
  };

  return (
    <LoginContainer>
      <LoginForm>
        <Input 
          type="email" 
          value={email} 
          onChange={e => setEmail(e.target.value)} 
          placeholder="Email" 
        />
        <Input 
          type="password" 
          value={password} 
          onChange={e => setPassword(e.target.value)} 
          placeholder="Password" 
        />
        <Button onClick={handleLogin} disabled={loading}>Login</Button>
        <FeedbackMessage style={{ color: feedback.startsWith('Login successful') ? '#28a745' : '#ff6b6b' }}>
          {feedback}
        </FeedbackMessage>
      </LoginForm>
    </LoginContainer>
  );
};

export default Login;
