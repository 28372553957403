// ArualTopFlags.js
import React from 'react';
import styled from 'styled-components';

const TopFlagsContainer = styled.div`
  background-image: url('/arual/cloud_BG_001.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  min-height: 100vh; // Ensure full viewport height
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 0;
`;

const TextContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0;
`;

const TextMain = styled.p`
  position: absolute;
  color: white;
  font-size: 30px;
  text-align: center;
  width: 100%;
  text-shadow: 2px 2px 2px rgba(0, 0, 0, 0.2);
  top: 20%; // Adjusted to push the text down by 20% from the top
  transform: translateY(-50%);
`;


const ImagesContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-end;
  padding-bottom: 10%; // 10% from the bottom
  gap: 40px; // Spacing between images
`;

const ImageWrapper = styled.div`
  width: 25%; // Default size as a percentage of the container width
  height: auto;
  max-width: 300px; // Max width to prevent overly large images on big screens
  img {
    width: 100%;
    height: auto;
  }

  @media (max-width: 1600px) {
    width: 30%; // Slightly larger on smaller screens
  }

  @media (max-width: 1200px) {
    width: 40%; // Even larger on medium screens
  }

  @media (max-width: 768px) {
    width: 80%; // Full width on small screens
  }
`;

const ArualTopFlags = () => {
  return (
    <TopFlagsContainer>
      <TextContainer>
        <TextMain>
          ARUAL ENSURES YOU DON’T FORGET OR<br />
          LOSE CONTROL OVER YOUR SUBSCRIPTIONS
        </TextMain>
      </TextContainer>
      <ImagesContainer>
        <ImageWrapper>
          <img src="/arual/sign_Control.png" alt="Control" />
        </ImageWrapper>
        <ImageWrapper>
          <img src="/arual/sign_peace.png" alt="Peace" />
        </ImageWrapper>
        <ImageWrapper>
          <img src="/arual/sign_saveMoney.png" alt="Save Money" />
        </ImageWrapper>
      </ImagesContainer>
    </TopFlagsContainer>
  );
};

export default ArualTopFlags;
