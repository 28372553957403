import React, { useEffect } from 'react';
import styled from 'styled-components';

const VideoPlayerContainer = styled.div`
  position: relative;
  width: 100%;
  height: 800px;
  overflow: hidden;
  background-color: black;
`;

const VideoBackground = styled.div`
  position: absolute;
  top: 0;
  left: 50%;
  width: 120%;
  height: 800px;
  transform: translateX(-50%);
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    pointer-events: none;
  }
`;

const DotPatternOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle, rgba(0, 0, 0, 0.3) 1px, transparent 1px);
  background-size: 5px 5px;
  pointer-events: none;
`;

const VideoPlayerTextContainer = styled.div`
  position: absolute;
  top: 55%; // Adjusted top value to restore vertical position
  left: calc(40% - 100px); // Offset by 100px to the left of the center
  transform: translateX(-50%);
  width: 450px;
  height: 180px;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  padding: 20px;
  color: white;
`;




const VideoPlayerText = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
`;

const VideoLink = styled.a`
  color: white;
  margin-top: 20px;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;

const TopLine = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 1px;
  background-color: #666666;
  z-index: 10;
`;

const AdditionalContainer = styled.div`
  width: 100%;
  height: 250px;
  background-color: black;
  display: flex;
  justify-content: space-between; /* Distribute the nested containers evenly */
  align-items: flex-start;
  position: relative;
  padding: 0px;
`;

const GreyLine = styled.div`
  position: absolute;
  top: 0;
  width: 100%;
  height: 1px;
  background-color: #666666;
`;

const ParentContainer = styled.div`
  width: 90%;
  height: 100%; /* Full height of the parent container */
  display: flex;
  justify-content: space-around; /* Less space between nested containers */
  align-items: center; /* Center content horizontally */
  background-color: black; /* Testing purposes */
`;


const NestedContainer = styled.div`
  width: 260px;
  height: 100%;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 40px;
  box-sizing: border-box;
  word-wrap: break-word;
  overflow-wrap: break-word;
  white-space: normal;
  margin: 0 10px; /* Adjust margin as needed */
`;


const PlaceholderText = styled.p`
  color: white;
  text-align: left;
  margin-bottom: 10px;
  font-size: 12px;
  word-wrap: break-word; /* Ensure text wraps within the container */
  overflow-wrap: break-word; /* Ensure text wraps within the container */
  white-space: normal; /* Ensure text wraps within the container */
`;

const Title = styled.h2`
  color: white;
  font-weight: bold;
  margin-bottom: 10px;
  font-size: 16px;
  word-wrap: break-word; /* Ensure text wraps within the container */
  overflow-wrap: break-word; /* Ensure text wraps within the container */
  white-space: normal; /* Ensure text wraps within the container */
`;

const TagsList = styled.ul`
  list-style-type: none;
  padding: 0;
  margin: 0;
  color: white;
  font-size: 12px;
  width: 100%; /* Ensure the list takes the full width of the container */
  word-wrap: break-word; /* Ensure text wraps within the container */
  overflow-wrap: break-word; /* Ensure text wraps within the container */
  white-space: normal; /* Ensure text wraps within the container */
`;

const TagItem = styled.li`
  display: inline-block; /* Changed to inline-block to ensure proper wrapping */
  margin-right: 10px;
  word-wrap: break-word; /* Ensure text wraps within the container */
  overflow-wrap: break-word; /* Ensure text wraps within the container */
  white-space: normal; /* Ensure text wraps within the container */
`;

const VideoPlayer = () => {
  useEffect(() => {
    const onYouTubeIframeAPIReady = () => {
      new window.YT.Player('video-background', {
        videoId: '4DeSHu_1Va8',
        playerVars: {
          autoplay: 1,
          loop: 1,
          playlist: '4DeSHu_1Va8',
          controls: 0,
          showinfo: 0,
          modestbranding: 1,
          fs: 0,
          cc_load_policy: 0,
          iv_load_policy: 3,
          autohide: 1,
          rel: 0,
          disablekb: 1,
          mute: 1,
        },
        events: {
          onReady: (event) => {
            event.target.mute();
            event.target.playVideo();
          },
        },
      });
    };

    const loadYouTubeIframeAPI = () => {
      if (!window.YT) {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
      } else {
        onYouTubeIframeAPIReady();
      }
    };

    loadYouTubeIframeAPI();
  }, []);

  return (
    <>
      <VideoPlayerContainer>
        <TopLine />
        <VideoBackground id="video-background" />
        <DotPatternOverlay />
        <VideoPlayerTextContainer>
          <VideoPlayerText>
            <p>
              Echoes Of The Lost Wild is a conceptual trailer created as an experimental piece using Mid Journey as a generator for creating background art with an anime style. Other elements such as the character were animated in Blender with compositing and various motion effects in After Effects.
            </p>
            <VideoLink href="https://www.youtube.com/watch?v=4DeSHu_1Va8" target="_blank">
              Echoes Of The Lost Wild Trailer
            </VideoLink>
          </VideoPlayerText>
        </VideoPlayerTextContainer>
      </VideoPlayerContainer>
      <AdditionalContainer>
        <GreyLine />
        <ParentContainer>
          <NestedContainer>
            <Title>Contact Info</Title>
            <PlaceholderText>David Edwards</PlaceholderText>
            <PlaceholderText>Vancouver PR</PlaceholderText>
            <PlaceholderText>impossiblecreaturesmail(at)proton.me</PlaceholderText>
          </NestedContainer>
          <NestedContainer>
            <Title>Social</Title>
            <VideoLink href="https://www.linkedin.com/in/davidedwardsme" target="_blank">
              Linkedin
            </VideoLink>
          </NestedContainer>
          <NestedContainer>
            <Title>Tags</Title>
            <TagsList>
              <TagItem>VFX</TagItem>
              <TagItem>film</TagItem>
              <TagItem>TV</TagItem>
              <TagItem>Matte Painting</TagItem>
              <TagItem>Matte Painter</TagItem>
              <TagItem>DMP</TagItem>
              <TagItem>Artist</TagItem>
              <TagItem>Visual Effects</TagItem>
              <TagItem>Reel</TagItem>
              <TagItem>Avatar</TagItem>
              <TagItem>AI</TagItem>
              <TagItem>Chat GPT</TagItem>
              <TagItem>Open AI</TagItem>
              <TagItem>Mid Journey</TagItem>
              <TagItem>Reel</TagItem>
              <TagItem>Creative</TagItem>
              <TagItem>Creator</TagItem>
              <TagItem>Concept</TagItem>
              <TagItem>Design</TagItem>
              <TagItem>Environments</TagItem>
              <TagItem>Unreal</TagItem>
              <TagItem>3ds Max</TagItem>
              <TagItem>Blender</TagItem>
              <TagItem>Nuke</TagItem>
              <TagItem>Photoshop</TagItem>
            </TagsList>
          </NestedContainer>
        </ParentContainer>
      </AdditionalContainer>
    </>
  );
};

export default VideoPlayer;
