// src/firebase/firebaseConfig.js
import { initializeApp } from 'firebase/app';
import { getAuth } from 'firebase/auth';

// Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDqivV4M4j1QEiTxR1xlWURZqjaWJwY0v8",
  authDomain: "projectportfolio-cc1f5.firebaseapp.com",
  projectId: "projectportfolio-cc1f5",
  storageBucket: "projectportfolio-cc1f5.appspot.com",
  messagingSenderId: "99166514744",
  appId: "1:99166514744:web:f972fe1227928cb0db00cd",
  measurementId: "G-R45DBVE48G"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const auth = getAuth(app);

export { auth, app };
