import React, { useState } from 'react';
import styled, { keyframes } from 'styled-components';

const FooterContainer = styled.div`
  background-image: url('/arual/layout_Foot.png');
  background-size: 100% auto;
  background-position: bottom center;
  background-repeat: no-repeat;
  background-color: #4aa34a;
  width: 100%;
  height: 1140px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin: 0;
  padding: 0;
  top: 0;
`;

const floatAnimation = keyframes`
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(-40px);
  }
  100% {
    transform: translateY(0);
  }
`;

const BeeImage = styled.img`
  position: absolute;
  bottom: 380px;
  right: 30px;
  width: 300px;
  height: auto;
  animation: ${floatAnimation} 2.5s ease-in-out infinite;
`;

const BeeImageLeft = styled.img`
  position: absolute;
  bottom: 740px;
  left: 150px;
  width: 200px;
  height: auto;
  transform: scaleX(-1);
  animation: ${floatAnimation} 3s ease-in-out infinite;
`;

const InvisibleButton = styled.div`
  position: absolute;
  width: 60%;
  height: 80%;
  top: calc(50% - 100px); // Center the button vertically and move it up by 100px
  left: 50%; // Center the button horizontally in the container
  transform: translate(-50%, -50%);
  cursor: pointer;
  z-index: 2; // Ensure the button is above other elements
`;


const GifOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8); // Semi-transparent background
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 5; // Ensure the GIF is on top
`;

const GifImage = styled.img`
  z-index: 6; // Ensure the GIF is above the overlay
`;

const ArualFooter = () => {
  const [showGif, setShowGif] = useState(false);

  const handleButtonClick = () => {
    setShowGif(true);
  };

  const handleOverlayClick = () => {
    setShowGif(false);
  };

  return (
    <FooterContainer>
      <BeeImage src="/arual/bee_001.png" alt="Bee" />
      <BeeImageLeft src="/arual/bee_002.png" alt="Bee" />
      <InvisibleButton onClick={handleButtonClick} />
      {showGif && (
        <GifOverlay onClick={handleOverlayClick}>
          <GifImage src="/arual/arualGif_001.gif" alt="Arual GIF" />
        </GifOverlay>
      )}
    </FooterContainer>
  );
};

export default ArualFooter;
