import React from 'react';
import PropTypes from 'prop-types';
import HTMLFlipBook from 'react-pageflip';
import styled from 'styled-components';

const PageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: black;
`;

const Image = styled.img`
  max-width: 100%;
  max-height: 100%;
  object-fit: cover;
  filter: grayscale(100%);
`;

const Page = React.forwardRef(({ imageSrc }, ref) => (
  <PageContainer ref={ref}>
    <Image src={imageSrc} alt="Flipbook Page" />
  </PageContainer>
));

Page.displayName = 'FlipbookPage'; // Adding display name

Page.propTypes = {
  imageSrc: PropTypes.string.isRequired,
};

const Flipbook2 = () => {
  return (
    <HTMLFlipBook width={600} height={337.5} showCover={true} maxShadowOpacity={0.5}>
      <Page imageSrc="/branding/flipbooks/book_002_images/001.jpg" />
      <Page imageSrc="/branding/flipbooks/book_002_images/002.jpg" />
      <Page imageSrc="/branding/flipbooks/book_002_images/003.jpg" />
      <Page imageSrc="/branding/flipbooks/book_002_images/004.jpg" />
      <Page imageSrc="/branding/flipbooks/book_002_images/005.jpg" />
      <Page imageSrc="/branding/flipbooks/book_002_images/006.jpg" />
      <Page imageSrc="/branding/flipbooks/book_002_images/007.jpg" />
      <Page imageSrc="/branding/flipbooks/book_002_images/008.jpg" />
      <Page imageSrc="/branding/flipbooks/book_002_images/009.jpg" />
      <Page imageSrc="/branding/flipbooks/book_002_images/010.jpg" />
      <Page imageSrc="/branding/flipbooks/book_002_images/011.jpg" />
      <Page imageSrc="/branding/flipbooks/book_002_images/012.jpg" />
      <Page imageSrc="/branding/flipbooks/book_002_images/013.jpg" />
    </HTMLFlipBook>
  );
};

export default Flipbook2;
