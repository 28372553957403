import React from 'react';
import styled from 'styled-components';

const MidContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
`;

const TopContainer = styled.div`
  width: 100%;
  padding: 20px 0;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const FeatureContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
`;

const FeatureInnerContainer = styled.div`
  display: flex;
  width: 70%;
  background-color: #4aa34a;
  border-radius: 10px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
  overflow: hidden;
  padding: 20px;
  box-sizing: border-box;
`;

const FeatureImage = styled.div`
  flex: 0 0 24%;
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  height: 280px;
  margin: auto 0;
  padding: 10px;
  box-sizing: border-box;
`;

const FeatureTextContainer = styled.div`
  flex: 0 0 70%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 20px;
  box-sizing: border-box;
`;

const FeatureTitle = styled.h3`
  color: #ffcc00;
  margin: 0 0 10px 0;
  font-size: 30px;
  line-height: 1.2;
  text-align: left;
`;

const FeatureDescription = styled.p`
  color: white;
  margin: 10px 0 0 0;
  font-size: 22px;
  line-height: 1.5;
  text-align: left;
`;

const BackgroundContainer = styled.div`
  background-image: url('/arual/layout_mid.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-color: #4aa34a;
  width: 100%;
  height: 600px; /* Ensures the container has the height of the background image */
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  margin-top: 20px; /* Add spacing above */
`;

const YellowCell = styled.div`
  width: 60%;
  height: 100px;
  background-color: #ffaf02;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 35px;
  max-width: 800px;
  min-width: 800px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
`;

const YellowCellTxt = styled.p`
  color: white;
  font-size: 20px;
  text-align: center;
  width: 100%;
`;



const ArualMid = () => {
  return (
    <MidContainer>
      <TopContainer>
        <FeatureContainer>
          <FeatureInnerContainer>
            <FeatureImage style={{ backgroundImage: "url('/arual/aural_img_001.png')" }} />
            <FeatureTextContainer>
              <FeatureTitle>TAKE CONTROL OF YOUR INCOMING CHARGES</FeatureTitle>
              <FeatureDescription>
                Arual Subscription Manager allows you to take control over incoming charges by ensuring you are notified two days in advance and have full clarity over your subscription forecast.
              </FeatureDescription>
            </FeatureTextContainer>
          </FeatureInnerContainer>
        </FeatureContainer>

        <FeatureContainer>
          <FeatureInnerContainer>
            <FeatureImage style={{ backgroundImage: "url('/arual/aural_img_005.png')" }} />
            <FeatureTextContainer>
              <FeatureTitle>NO MORE SURPRISES</FeatureTitle>
              <FeatureDescription>
                The heart of Arual Subscription Manager is the notification system that alerts you periodically within two days of a pending subscription fee; giving you time to take action and avoid unwanted charges.
              </FeatureDescription>
            </FeatureTextContainer>
          </FeatureInnerContainer>
        </FeatureContainer>

        <FeatureContainer>
          <FeatureInnerContainer>
            <FeatureImage style={{ backgroundImage: "url('/arual/aural_img_007.png')" }} />
            <FeatureTextContainer>
              <FeatureTitle>IN DEPTH ANALYSIS & DATA PRESENTATION</FeatureTitle>
              <FeatureDescription>
                Various charts allow you to review subscriptions so you have a clear and meaningful overview of your subscription forecast.
              </FeatureDescription>
            </FeatureTextContainer>
          </FeatureInnerContainer>
        </FeatureContainer>

        <FeatureContainer>
          <FeatureInnerContainer>
            <FeatureImage style={{ backgroundImage: "url('/arual/aural_img_003.png')" }} />
            <FeatureTextContainer>
              <FeatureTitle>HAVE FULL CLARITY OVER THE FUTURE</FeatureTitle>
              <FeatureDescription>
                An intuitive system of graphs help you visually see upcoming subscription fees due to be taken and allow you to have clarity over future expenses.
              </FeatureDescription>
            </FeatureTextContainer>
          </FeatureInnerContainer>
        </FeatureContainer>

        <FeatureContainer>
          <FeatureInnerContainer>
            <FeatureImage style={{ backgroundImage: "url('/arual/aural_img_002.png')" }} />
            <FeatureTextContainer>
              <FeatureTitle>INTUITIVE INTERFACE</FeatureTitle>
              <FeatureDescription>
                Intuitive and dynamic interface that provides efficient and economical use of the user interface to present data is a clear and concise way.
              </FeatureDescription>
            </FeatureTextContainer>
          </FeatureInnerContainer>
        </FeatureContainer>

        <FeatureContainer>
          <FeatureInnerContainer>
            <FeatureImage style={{ backgroundImage: "url('/arual/aural_img_006.png')" }} />
            <FeatureTextContainer>
              <FeatureTitle>SEE WHICH SUBSCRIPTIONS HAVE REAL VALUE</FeatureTitle>
              <FeatureDescription>
                Have complete clarity over what subscriptions have high value over subscriptions that have less value and should be reviewed for unsubscribing. This way you know what to keep and what to lose.
              </FeatureDescription>
            </FeatureTextContainer>
          </FeatureInnerContainer>
        </FeatureContainer>
      </TopContainer>

      <BackgroundContainer>
        <YellowCell>
          <YellowCellTxt>
            More than just a basic manager, Arual takes aim at forgotten and unused <br />subscriptions to ensure you avoid unwanted charges and save money!
          </YellowCellTxt>
        </YellowCell>
      </BackgroundContainer>

    </MidContainer>
  );
};

export default ArualMid;
