import React from 'react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import styled from 'styled-components';

const Nav = styled.nav`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 40px;
  border-bottom: 0px solid white;
  background-color: black;
  height: 80px; /* Adjusted height */
  position: fixed;
  top: 0;
  width: 100%;
  z-index: 1000;
`;

const Logo = styled.img`
  height: 70px; /* Explicitly set the height */
  width: auto; /* Maintain aspect ratio */
  margin-right: 20px; /* Space between logo and navigation */
  margin-top: -10px; /* Push the logo up by 10 pixels */
`;

const NavList = styled.ul`
  list-style-type: none;
  padding: 0;
  display: flex;
  margin: 0;
  align-items: center; /* Ensure nav items are vertically centered */
`;

const NavItem = styled.li`
  margin-right: 20px;
`;

const NavLink = styled(Link)`
  color: white;
  text-decoration: none;
  padding: 8px 10px;
  border-radius: 30px;
  transition: background-color 0.3s, color 0.3s;
  border: 2px solid transparent;  // Ensures consistent sizing by always having a border

  &:hover, &:focus {
    background-color: #ffcc00;  // Yellow background on hover and focus
    color: black;  // Text color changes to black for better visibility
    text-decoration: none;  // Remove underline on hover
    outline: none;  // Remove any default browser outline styles
  }

  &:active {
    background-color: transparent;  // Remove background color on active state
    border: 2px solid #ffcc00;  // Yellow border on active state
    color: #ffcc00;  // Text color changes to yellow on active state
  }
`;

const YellowBar = styled.div`
  width: 100%;
  height: 20px;
  background-color: #ffcc00;
  position: absolute;
  bottom: 0;
  left: 0;
`;

const Navbar = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleProjectsClick = (e) => {
    e.preventDefault();
    if (location.pathname !== '/') {
      navigate('/?scrollTo=projects');
    } else {
      const projectsSection = document.getElementById('projects-section');
      if (projectsSection) {
        const offsetTop = projectsSection.offsetTop - 80; // Adjust for navbar height
        window.scrollTo({ top: offsetTop, behavior: 'smooth' });
      }
    }
  };

  return (
    <div style={{ position: 'relative', width: '100%' }}>
      <Nav>
        <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
          <Logo src="/branding/logo_Small.png" alt="Logo" />
        </div>
        <NavList style={{ paddingRight: '100px' }}>
          <NavItem>
            <NavLink to="/">Home</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="#" onClick={handleProjectsClick}>Projects</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/artwork">Artwork</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/contact">Contact</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/admin">Admin</NavLink>
          </NavItem>
          <NavItem>
            <NavLink to="/arual">Arual</NavLink> {/* New link added here */}
          </NavItem>
        </NavList>
        <YellowBar />
      </Nav>
    </div>
  );
};

export default Navbar;
