import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const CredentialsContainer = styled.div`
  width: 100%; // Full width of the page
  background-color: #000; // Black background
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 50px; // Add top margin for breathing room
`;

const Title = styled.h1`
  color: #fff; // White text color
  font-weight: bold; // Bold text
  margin-bottom: 20px; // Spacing below the title
`;

const Image = styled.img`
  width: 80%; // Ensure the image takes up 80% of the container's width
  height: auto; // Maintain the aspect ratio
  margin-top: 20px; // Spacing above the image
`;

const LogosContainer = styled.div`
  width: 100%; // Full width of the page
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px 0; // Add padding for spacing around the logos
`;

const LogosImage = styled.img`
  width: 50%; // Scale down the logos image by 50%
  height: auto; // Maintain the aspect ratio
`;

const ArtworkCredentials = () => {
  return (
    <CredentialsContainer>
      <Title>Credits</Title>
      <Image src="/credits_001.png" alt="Credits" />
      <LogosContainer>
        <LogosImage src="/logos_001.png" alt="Logos" />
      </LogosContainer>
    </CredentialsContainer>
  );
};

ArtworkCredentials.propTypes = {
  creditsImageSrc: PropTypes.string.isRequired,
  logosImageSrc: PropTypes.string.isRequired,
};

export default ArtworkCredentials;
