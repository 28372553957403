import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  @import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

  body {
    background-color: black;
    color: #ffffff;
    font-family: 'Montserrat', sans-serif !important; /* Using Montserrat font with !important */
    margin: 0;
    padding: 0;
  }

  h1, h2, h3 {
    color: #ffcc00;
    font-family: 'Montserrat', sans-serif !important;
  }

  input, textarea, button {
    font-family: 'Montserrat', sans-serif !important; /* Using Montserrat font with !important */
    margin: 5px 0;
  }

  input, textarea {
    background-color: #1f1f1f;
    color: #ffffff;
    border: 1px solid #333333;
    padding: 10px;
    border-radius: 5px;
    width: 100%;
  }

  button {
    background-color: #ffcc00;
    color: #000000;
    border: none;
    padding: 10px 20px;
    border-radius: 5px;
    cursor: pointer;
  }

  button:hover {
    background-color: #e6b800;
  }
`;

export default GlobalStyle;
