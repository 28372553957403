import React from 'react';
import PropTypes from 'prop-types';
import { FlipbookListContainer, FlipbookThumbnails, Thumbnail, CoverImageContainer, HeadlineBase, Logo, HighlightContainer, SmallLogo } from '../styles/flipbookStyles';
import styled from 'styled-components';

const ParagraphContainer = styled.div`
  width: 100%;
  background-color: #000;
  padding: 40px 0;
  display: flex;
  justify-content: center;
`;

const TextContainer = styled.div`
  width: 70%;
  color: #fff;
  font-size: 14px;
  line-height: 1.6;
  text-align: center;
  padding: 20px;
`;

const ContentWrapper = styled.div`
  display: flex;
  justify-content: center; /* Centers the content horizontally */
  align-items: center; /* Aligns the content vertically */
  width: 100%;
  max-width: 1200px; /* Limits the content width for larger screens */
  margin: 0 auto;
`;

const FlipbookList = ({ onThumbnailClick }) => {
  return (
    <>
      <FlipbookListContainer>
        <HeadlineBase href="https://society6.com/impossible_creatures" target="_blank">
          <ContentWrapper>
            <Logo src="/branding/Society6-logo.png" alt="Society6 Logo" />
            <div style={{ display: 'flex', alignItems: 'center', height: '100%' }}>
              <HighlightContainer>
                Impossible Creatures prints with Society6 available for purchase.
              </HighlightContainer>
              <SmallLogo src="/branding/mj-logo.png" alt="MidJourney Logo" />
              <SmallLogo src="/branding/gpt-logo1.png" alt="GPT Logo" />
            </div>
          </ContentWrapper>
        </HeadlineBase>
        <CoverImageContainer>
          <img src="/branding/flipbooks/cover_001.jpg" alt="Cover" />
        </CoverImageContainer>
        <FlipbookThumbnails>
          <Thumbnail
            src="/branding/flipbooks/flipbook_001_cover.png"
            alt="Flipbook 1"
            onClick={() => onThumbnailClick(1)}
          />
          <Thumbnail
            src="/branding/flipbooks/flipbook_002_cover.png"
            alt="Flipbook 2"
            onClick={() => onThumbnailClick(2)}
          />
          <Thumbnail
            src="/branding/flipbooks/flipbook_003_cover.png"
            alt="Flipbook 3"
            onClick={() => onThumbnailClick(3)}
          />
        </FlipbookThumbnails>
      </FlipbookListContainer>
      <ParagraphContainer>
        <TextContainer>
          Impossible Creatures is born from the idea of creatures escaping evolution to become something more than the sum of their limitations. Humanity is currently at the point of augmenting ourselves in extraordinary ways, allowing us for the first time in 300,000 years to escape the process of natural evolution and choose our own path. These images, created with MidJourney and Photo editing, explore the concept of modified creatures that have become enhanced, symbolizing the potential for us to transcend our natural boundaries through technological innovation.
          <br /><br />
          Print purchases available from <a href="https://society6.com/impossible_creatures" target="_blank" rel="noopener noreferrer" style={{ color: '#FFCC00' }}>Society6</a>
        </TextContainer>
      </ParagraphContainer>
    </>
  );
};

FlipbookList.propTypes = {
  onThumbnailClick: PropTypes.func.isRequired,
};

export default FlipbookList;
