import React, { useEffect } from 'react';
import styled from 'styled-components';

const VideoContainer = styled.div`
  position: relative;
  width: 100%;
  height: 600px; // Fixed height for the video container
  overflow: hidden;
  margin-top: 100px; // Offset for the navbar, adjust based on actual navbar height
`;

const VideoBackground = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100vw;
  height: calc(100vw * (9 / 16)); // Adjust based on the video's aspect ratio
  min-height: 600px; // Ensure it covers the container's height fully
  pointer-events: none; // Disable user interaction
`;

const DotPatternOverlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-image: radial-gradient(circle, rgba(0, 0, 0, 0.3) 1px, transparent 1px);
  background-size: 5px 5px;
  pointer-events: none; // Allows clicks to pass through to the video
`;

const ArtworkVideoTitle = styled.div`
  position: absolute;
  top: 50%;
  left: 10%; /* Adjust to move to the left side */
  transform: translate(-100px, -50%);
  color: white;
  font-size: 3em; /* Large and bold text size */
  font-weight: bold;
  text-align: center;
  padding: 10px;
  background: rgba(0, 0, 0, 0); /* Optional: add a semi-transparent background for better readability */
  border-radius: 10px;
  width: 35%; /* Set the width to 40% */
`;

const AdditionalText = styled.div`
  position: absolute;
  bottom: 20px; /* Position it close to the bottom */
  left: 50%;
  transform: translateX(-50%);
  color: white;
  font-size: 1.5em; /* Adjust the text size as needed */
  font-weight: bold;
  text-align: center;
  padding: 10px;
  background: rgba(0, 0, 0, 0); /* Optional: add a semi-transparent background for better readability */
  border-radius: 10px;
  width: 70%; /* Adjust the width as needed */
`;

const ArtworkVideo = () => {
  useEffect(() => {
    const onYouTubeIframeAPIReady = () => {
      new window.YT.Player('artwork-video-background', {
        videoId: '2xS2vxcyhHY', // YouTube video ID
        playerVars: {
          autoplay: 1,
          loop: 1,
          playlist: '2xS2vxcyhHY',
          controls: 0,
          showinfo: 0,
          modestbranding: 1,
          fs: 0,
          cc_load_policy: 0,
          iv_load_policy: 3,
          autohide: 1,
          rel: 0,
          disablekb: 1,
          mute: 1 // Mute the video
        },
        events: {
          onReady: (event) => {
            event.target.mute();
            event.target.playVideo();
            event.target.setPlaybackQuality('highres'); // Attempt to set the highest video quality
          }
        },
      });
    };

    const loadYouTubeIframeAPI = () => {
      if (!window.YT) {
        const tag = document.createElement('script');
        tag.src = 'https://www.youtube.com/iframe_api';
        const firstScriptTag = document.getElementsByTagName('script')[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = onYouTubeIframeAPIReady;
      } else {
        onYouTubeIframeAPIReady();
      }
    };

    loadYouTubeIframeAPI();
  }, []);

  return (
    <VideoContainer>
      <VideoBackground id="artwork-video-background" />
      <DotPatternOverlay />
      <ArtworkVideoTitle>
        CREATIVE ARTWORK & MOTION GRAPHICS <br /> OF DAVID EDWARDS
      </ArtworkVideoTitle>
      <AdditionalText>
        Matte Painter - Environment Artist - Animator - Illustrator - VFX Artist
      </AdditionalText>
    </VideoContainer>
  );
};

export default ArtworkVideo;
