import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import useNavigate
import { signOut } from 'firebase/auth'; // Import signOut from firebase/auth
import { auth } from '../firebase/firebaseConfig'; // Import auth from your firebase config
import { fetchProjects, createProject, updateProject, deleteProject, fetchHeadline, updateHeadline, fetchContactEmail, updateContactEmail } from '../api';
import styled from 'styled-components';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';

const AdminContainer = styled.div`
  padding: 20px;
  max-width: 800px;
  margin: 0 auto;
`;

const Section = styled.div`
  margin-bottom: 40px;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 10px;
`;

const Button = styled.button`
  align-self: flex-start;
  background-color: #ffcc00;
  color: #000000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #e6b800;
  }
  &:active {
    background-color: #d4a600;
  }
`;

const ProjectList = styled.ul`
  list-style-type: none;
  padding: 0;
`;

const ProjectItem = styled.li`
  margin-bottom: 20px;
`;

const FeedbackMessage = styled.div`
  margin-top: 10px;
  color: #ffcc00;
`;

const LogoutButton = styled.button`
  position: fixed;
  bottom: 20px;
  right: 20px;
  background-color: #ffcc00;
  color: black;
  border: none;
  padding: 10px 20px;
  border-radius: 30px;
  cursor: pointer;
  z-index: 1000;
  &:hover {
    background-color: #e6b800;
  }
  &:active {
    background-color: #d4a600;
  }
`;




// Define the imageHandler function
const imageHandler = function() {
  const input = document.createElement('input');
  input.setAttribute('type', 'file');
  input.setAttribute('accept', 'image/*');
  input.click();

  input.onchange = async () => {
    const file = input.files[0];
    const formData = new FormData();
    formData.append('image', file);

    try {
      const response = await fetch(`${process.env.REACT_APP_API_URL}/upload-image`, {
        method: 'POST',
        body: formData,
      });

      const data = await response.json();
      const range = this.quill.getSelection();
      this.quill.insertEmbed(range.index, 'image', `${process.env.REACT_APP_STATIC_URL}${data.imageUrl}`);
      const [image] = this.quill.container.querySelectorAll(`img[src="${process.env.REACT_APP_API_URL}${data.imageUrl}"]`);
      if (image) {
        image.style.width = '50%'; // Default width to 50%
        image.setAttribute('data-resizable', 'true');
        image.setAttribute('class', 'resizable-image');
      }
    } catch (error) {
      console.error('Error uploading image:', error);
    }
  };
};


const modules = {
  toolbar: {
    container: [
      [{ 'header': '1' }, { 'header': '2' }, { 'font': [] }],
      [{ 'size': [] }],
      ['bold', 'italic', 'underline', 'strike', 'blockquote'],
      [{ 'list': 'ordered' }, { 'list': 'bullet' }, { 'indent': '-1' }, { 'indent': '+1' }],
      ['link', 'image', 'video'],
      [{ 'align': [] }],
      [{ 'color': [] }, { 'background': [] }],
      ['clean']
    ],
    handlers: {
      image: function () {
        imageHandler.call(this);
      },
    }
  },
  clipboard: {
    matchVisual: false,
  }
};

const formats = [
  'header', 'font', 'size',
  'bold', 'italic', 'underline', 'strike', 'blockquote',
  'list', 'bullet', 'indent',
  'link', 'image', 'video',
  'align', 'color', 'background'
];

const Admin = () => {
  const [projects, setProjects] = useState([]);
  const [newProject, setNewProject] = useState({ title: '', description: '', details: '', image: '' });
  const [editProject, setEditProject] = useState(null);
  const [imageFile, setImageFile] = useState(null);
  const [headline, setHeadline] = useState({ title: '', text: '', image: '' });
  const [headlineImageFile, setHeadlineImageFile] = useState(null);
  const [headlineFeedback, setHeadlineFeedback] = useState('');
  const [projectFeedback, setProjectFeedback] = useState('');
  const [editFeedback, setEditFeedback] = useState('');
  const [contactEmail, setContactEmail] = useState('');
  const [emailFeedback, setEmailFeedback] = useState('');
  const navigate = useNavigate();

  useEffect(() => {
    const getProjects = async () => {
      const projectsData = await fetchProjects();
      setProjects(projectsData);
    };

    const getHeadline = async () => {
      const headlineData = await fetchHeadline();
      setHeadline(headlineData);
    };

    const getContactEmail = async () => {
      const email = await fetchContactEmail();
      setContactEmail(email);
    };

    getProjects();
    getHeadline();
    getContactEmail();
  }, []);

  const handleCreateProject = async () => {
    const formData = new FormData();
    formData.append('title', newProject.title);
    formData.append('description', newProject.description);
    formData.append('details', newProject.details);
    if (imageFile) {
      formData.append('image', imageFile);
    }

    try {
      const createdProject = await createProject(formData);
      setProjects([...projects, { id: createdProject.id, ...newProject }]);
      setNewProject({ title: '', description: '', details: '', image: '' });
      setImageFile(null);
      setProjectFeedback('Project created successfully.');
    } catch (error) {
      setProjectFeedback('Failed to create project.');
    }
  };

  const handleUpdateProject = async () => {
    const formData = new FormData();
    formData.append('title', editProject.title);
    formData.append('description', editProject.description);
    formData.append('details', editProject.details);
    if (imageFile) {
      formData.append('image', imageFile);
    }

    try {
      await updateProject(editProject.id, formData);
      setProjects(projects.map(p => (p.id === editProject.id ? { id: editProject.id, ...editProject } : p)));
      setEditProject(null);
      setImageFile(null);
      setEditFeedback('Project updated successfully.');
    } catch (error) {
      setEditFeedback('Failed to update project.');
    }
  };

  const handleDeleteProject = async (id) => {
    try {
      await deleteProject(id);
      setProjects(projects.filter(p => p.id !== id));
      setEditFeedback('Project deleted successfully.');
    } catch (error) {
      setEditFeedback('Failed to delete project.');
    }
  };

  const handleUpdateHeadline = async () => {
    const formData = new FormData();
    formData.append('title', headline.title);
    formData.append('text', headline.text);
    if (headlineImageFile) {
      formData.append('image', headlineImageFile);
    }

    try {
      const updatedHeadline = await updateHeadline(formData);
      setHeadline(updatedHeadline);
      setHeadlineImageFile(null);
      setHeadlineFeedback('Headline updated successfully.');
    } catch (error) {
      setHeadlineFeedback('Failed to update headline.');
    }
  };

  const handleUpdateContactEmail = async () => {
    try {
      await updateContactEmail(contactEmail);
      setEmailFeedback('Contact email updated successfully.');
    } catch (error) {
      setEmailFeedback('Failed to update contact email.');
    }
  };

  const handleLogout = async () => {
    try {
      await signOut(auth);
      navigate('/login');
    } catch (error) {
      console.error('Logout failed:', error);
    }
  };

  return (
    <AdminContainer>
      <h2>Admin Panel</h2>
      <Section>
        <h3>Update Headline</h3>
        <Form>
          <Input
            type="text"
            placeholder="Headline Title"
            value={headline.title}
            onChange={e => setHeadline({ ...headline, title: e.target.value })}
          />
          <ReactQuill
            value={headline.text}
            onChange={value => setHeadline({ ...headline, text: value })}
            modules={modules}
            formats={formats}
          />
          <Input
            type="file"
            onChange={e => setHeadlineImageFile(e.target.files[0])}
          />
          <Button onClick={handleUpdateHeadline}>Update Headline</Button>
          {headlineFeedback && <FeedbackMessage>{headlineFeedback}</FeedbackMessage>}
        </Form>
      </Section>
      <Section>
        <h3>Add New Project</h3>
        <Form>
          <Input
            type="text"
            placeholder="Title"
            value={newProject.title}
            onChange={e => setNewProject({ ...newProject, title: e.target.value })}
          />
          <Input
            type="text"
            placeholder="Description"
            value={newProject.description}
            onChange={e => setNewProject({ ...newProject, description: e.target.value })}
          />
          <ReactQuill
            value={newProject.details}
            onChange={value => setNewProject({ ...newProject, details: value })}
            modules={modules}
            formats={formats}
          />
          <Input
            type="file"
            onChange={e => setImageFile(e.target.files[0])}
          />
          <Button onClick={handleCreateProject}>Add Project</Button>
          {projectFeedback && <FeedbackMessage>{projectFeedback}</FeedbackMessage>}
        </Form>
      </Section>
      <Section>
        <h3>Edit Project</h3>
        {editProject && (
          <Form>
            <Input
              type="text"
              placeholder="Title"
              value={editProject.title}
              onChange={e => setEditProject({ ...editProject, title: e.target.value })}
            />
            <Input
              type="text"
              placeholder="Description"
              value={editProject.description}
              onChange={e => setEditProject({ ...editProject, description: e.target.value })}
            />
            <ReactQuill
              value={editProject.details}
              onChange={value => setEditProject({ ...editProject, details: value })}
              modules={modules}
              formats={formats}
            />
            <Input
              type="file"
              onChange={e => setImageFile(e.target.files[0])}
            />
            <Button onClick={handleUpdateProject}>Update Project</Button>
            {editFeedback && <FeedbackMessage>{editFeedback}</FeedbackMessage>}
          </Form>
        )}
      </Section>
      <Section>
        <h3>Project List</h3>
        <ProjectList>
          {projects.map(project => (
            <ProjectItem key={project.id}>
              <h4>{project.title}</h4>
              <Button onClick={() => setEditProject(project)}>Edit</Button>
              <Button onClick={() => handleDeleteProject(project.id)}>Delete</Button>
            </ProjectItem>
          ))}
        </ProjectList>
        {editFeedback && <FeedbackMessage>{editFeedback}</FeedbackMessage>}
      </Section>
      <Section>
        <h3>Contact Email</h3>
        <Form>
          <Input
            type="email"
            placeholder="Contact Email"
            value={contactEmail}
            onChange={e => setContactEmail(e.target.value)}
          />
          <Button onClick={handleUpdateContactEmail}>Update Contact Email</Button>
          {emailFeedback && <FeedbackMessage>{emailFeedback}</FeedbackMessage>}
        </Form>
      </Section>
      <LogoutButton onClick={handleLogout}>Log Out</LogoutButton>
    </AdminContainer>
  );
};

export default Admin;
