import React, { useEffect, useState } from 'react';
import ProjectList from '../components/ProjectList';
import HeadlineSection from '../components/HeadlineSection';
import SidePanel from '../components/SidePanel';
import FlipbookList from '../components/FlipbookList';
import Flipbook1 from '../components/Flipbook1';
import Flipbook2 from '../components/Flipbook2';
import Flipbook3 from '../components/Flipbook3';
import VideoPlayer from '../components/VideoPlayer';
import { Overlay, FlipbookModalContainer, CloseButton } from '../styles/flipbookStyles';
import styled from 'styled-components';
import { Helmet } from 'react-helmet'; 

import { useLocation } from 'react-router-dom';

const HomeContainer = styled.div`
  color: white;
  padding-top: 100px; // Add padding to offset fixed navbar height
`;

const HeadlineSidePanelContainer = styled.div`
  display: flex;
  overflow: hidden;
  position: relative;
  border-bottom: 1px solid #666666; /* Thin grey line at the bottom */
  background-color: black;
  width: 100%; // Make sure the container takes the full width of the viewport
  height: auto; // Adjust height automatically based on content
`;

const Home = () => {
  const location = useLocation();
  const [showFlipbook, setShowFlipbook] = useState(false);
  const [selectedFlipbook, setSelectedFlipbook] = useState(null);
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  useEffect(() => {
    const params = new URLSearchParams(location.search);
    const scrollTo = params.get('scrollTo');
    if (scrollTo === 'projects') {
      const projectsSection = document.getElementById('projects-section');
      if (projectsSection) {
        const offsetTop = projectsSection.offsetTop - 100; // Adjust for navbar height
        window.scrollTo({ top: offsetTop, behavior: 'smooth' });
      }
    }
  }, [location]);

  const handleThumbnailClick = (flipbookNumber) => {
    setSelectedFlipbook(flipbookNumber);
    setShowFlipbook(true);
  };

  const handleCloseFlipbook = () => {
    setShowFlipbook(false);
    setSelectedFlipbook(null);
  };

  const togglePanel = () => {
    setIsPanelOpen(!isPanelOpen);
  };

  return (
    <HomeContainer>
      <Helmet>
        <title>Impossible Creatures - Time To Evolve</title>
        <meta name="description" content="Impossible Creatures is a showcase of experimental ideas, and creations using A.I tools such as Chat GPT and Mid Journey; including professional artwork" />
        <meta name="keywords" content="impossible creatures, AI, artificial intelligence, Chat GPT, Mid Journey, artwork, VFX, film, visual effect, artist, software developer" />
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content="Impossible Creatures AI projects and Artwork" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.impossiblecreatures.com/branding/SocialMedia/OG_1200x630_002.png" />
        <meta property="og:url" content="https://www.impossiblecreatures.com" />
        <meta property="og:description" content="Explore revolutionary AI-driven artwork and technological advancements with Impossible Creatures." />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@impossiblecrits" />
        <meta name="twitter:title" content="Impossible Creatures" />
        <meta name="twitter:description" content="Augmenting the human condition with AI exploration." />
        <meta name="twitter:image" content="https://www.impossiblecreatures.com/branding/SocialMedia/Twitter_1200x628_002.png" />
        <link rel="canonical" href="https://www.impossiblecreatures.com" />
      </Helmet>
      <HeadlineSidePanelContainer>
        <SidePanel isOpen={isPanelOpen} togglePanel={togglePanel} />
        <HeadlineSection isPanelOpen={isPanelOpen} togglePanel={togglePanel} />
      </HeadlineSidePanelContainer>
      <div id="projects-section">
        <ProjectList />
      </div>
      <FlipbookList onThumbnailClick={handleThumbnailClick} />
      <VideoPlayer />
      {showFlipbook && (
        <Overlay onClick={handleCloseFlipbook}>
          <FlipbookModalContainer onClick={(e) => e.stopPropagation()}>
            {selectedFlipbook === 1 && <Flipbook1 />}
            {selectedFlipbook === 2 && <Flipbook2 />}
            {selectedFlipbook === 3 && <Flipbook3 />}
            <CloseButton onClick={handleCloseFlipbook}>X</CloseButton>
          </FlipbookModalContainer>
        </Overlay>
      )}
    </HomeContainer>
  );
};

export default Home;
