import React, { useEffect, useState } from 'react';
import ProjectItem from './ProjectItem';
import { fetchProjects } from '../api';
import styled from 'styled-components';

// Container for the list of projects
const ProjectListContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  padding: 20px;
  margin-top: 30px; // Adjust margin-top to reduce the gap
`;

// Wrapper for each project item
const ProjectItemWrapper = styled.div`
  flex: 1 1 calc(33.3333% - 20px); // Adjust the width and spacing
  max-width: calc(33.3333% - 20px); // Ensure items do not exceed the width
  margin: 10px;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex: 1 1 calc(50% - 20px); // Two items per row on smaller screens
    max-width: calc(50% - 20px);
  }

  @media (max-width: 480px) {
    flex: 1 1 100%; // One item per row on very small screens
    max-width: 100%;
  }
`;

// Yellow bar component
const YellowBar = styled.div`
  background-color: #ffcc00;
  width: 70%;
  height: 50px;
  border-radius: 30px;
  margin: 30px auto 20px auto; // Center the bar and add margin to the top and bottom
  display: flex;
  justify-content: center;
  align-items: center;
`;

// Text inside the yellow bar
const BarText = styled.span`
  color: black;
  font-size: 20px;
  font-weight: bold;
`;

// Paragraph for additional text under the project list
const TextParagraph = styled.p`
  color: white; // Set the text color to white
  font-size: 1.1rem; // Medium size body copy
  line-height: 1.6; // Appropriate line height for readability
  text-align: center; // Center-align the text
  margin: 40px 20px; // Top and bottom margin for spacing, horizontal margin for smaller screens
  padding: 0 10%; // Padding to prevent text from sticking to the edges
  width: 100%; // Ensure the container takes the full width
  box-sizing: border-box; // Include padding and border in the width
`;

// ProjectList component definition
const ProjectList = () => {
  const [projects, setProjects] = useState([]);

  useEffect(() => {
    const getProjects = async () => {
      try {
        const projectsData = await fetchProjects();
        if (Array.isArray(projectsData)) {
          setProjects(projectsData);
        } else {
          console.error('Unexpected data format:', projectsData);
          setProjects([]); // Set to an empty array if the data format is incorrect
        }
      } catch (error) {
        console.error('Error fetching projects:', error);
        setProjects([]); // Set to an empty array if there's an error
      }
    };

    getProjects();
  }, []);

  return (
    <ProjectListContainer>
      <YellowBar>
        <BarText>Experimental projects created with Open AI Chat GPT.</BarText>
      </YellowBar>
      {projects.map(project => (
        <ProjectItemWrapper key={project.id}>
          <ProjectItem project={project} />
        </ProjectItemWrapper>
      ))}
      <TextParagraph>
        Since OpenAI ChatGPT came to my attention in 2022, I&apos;ve used the tool to augment my workflow, build applications, plugins, and websites. Wherever there&apos;s an opportunity to leverage the tool to speed up and enhance what I&apos;m already doing, I embrace it. I integrate this technology into both my personal and professional life wherever possible. I sincerely hope my work can provide insight to others and serve as an inspiration. Currently, I have several projects in development, each more ambitious than the last, with each project greater in scope than the last as I attempt to see where the limitations exist.
      </TextParagraph>
    </ProjectListContainer>
  );
};

export default ProjectList;
