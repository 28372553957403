import { useState, useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { app } from '../firebase/firebaseConfig';

export const useAuth = () => {
  const [currentUser, setCurrentUser] = useState(null);
  const [loading, setLoading] = useState(true); // Add loading state
  const auth = getAuth(app);

  useEffect(() => {
    const unsubscribe = onAuthStateChanged(auth, user => {
      console.log("Auth state changed, user:", user);
      setCurrentUser(user);
      setLoading(false); // Set loading to false after auth state changes
    });
    return () => unsubscribe();
  }, [auth]);

  return { currentUser, loading };
};
