import React from 'react';
import ArtworkHeader from '../components/ArtworkHeader';
import { Helmet } from 'react-helmet';  // Import Helmet
import ArtworkCarousel from '../components/ArtworkCarousel';  // Ensure the path is correct
import ArtworkCredentials from '../components/ArtworkCredentials'; // Import the new component

const Artwork = () => {
  return (
    <div style={{ marginTop: 0, paddingTop: 0 }}>
      <Helmet>
        <title>Impossible Creatures - Creating programs and Tools</title>
        <meta name="description" content="Impossible Creatures is a showcase of experimental tools and software created with Chat GPT." />
        <meta name="keywords" content="impossible creatures, AI, artificial intelligence, Chat GPT, Mid Journey, software developer, plugins, addons, blender" />
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content="Impossible Creatures" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.impossiblecreatures.com/branding/SocialMedia/OG_1200x630_002.png" />
        <meta property="og:url" content="https://www.impossiblecreatures.com" />
        <meta property="og:description" content="Explore revolutionary AI-driven artwork and technological advancements with Impossible Creatures." />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@impossiblecrits" />
        <meta name="twitter:title" content="Impossible Creatures" />
        <meta name="twitter:description" content="Augmenting the human condition with AI exploration." />
        <meta name="twitter:image" content="https://www.impossiblecreatures.com/branding/SocialMedia/Twitter_1200x628_002.png" />
        <link rel="canonical" href="https://www.impossiblecreatures.com" />
      </Helmet>
      <ArtworkHeader />
      <ArtworkCarousel />
      <ArtworkCredentials />
    </div>
  );
};


export default Artwork;
