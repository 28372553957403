import React from 'react';
import styled from 'styled-components';

const PreFootContainer = styled.div`
  width: 100%;
  background-color: #4aa34a; // Set the background color to green
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0; // Adds some padding to the top and bottom for breathing room
`;

const VideoContainer = styled.div`
  width: 1024px; // Fixed width
  height: 576px; // Fixed height to maintain 16:9 aspect ratio
  position: relative;
  margin-bottom: 20px; // Adds space between the video and the text
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`;

const VideoTitle = styled.p`
  color: white;
  font-size: 24px; // Slightly larger for visibility
  text-align: center;
  margin-bottom: 20px; // Adds space between the text and the next section
`;

const PreFootContainerInt = styled.div`
  background-image: url('/arual/layout-002.png');
  background-size: 100% auto; // Maintains the aspect ratio
  background-position: bottom center; // Aligns the image with the bottom
  background-repeat: no-repeat; // Prevents repeating
  background-color: #4aa34a; // Set the background color to green
  width: 800px;
  height: 244px; // Fixed height as specified
  display: flex;
  justify-content: center;
  align-items: center;
`;

const SpacerContainer = styled.div`
  position: relative; // Ensure this container has relative positioning
  width: 100%;
  height: 50px;
  display: flex;
  justify-content: center;
  flex-grow: 1;
  align-items: center;
  background-color: #4aa34a; // Matching the green background
`;

const SubscriptionText = styled.p`
  width: 700px;
  color: white;
  font-weight: light;
  font-size: 22px; // Medium size
  text-align: center;
  padding: 10px; // Adds some padding to ensure text doesn't touch the edges
  line-height: 1.8; // Adjusts the spacing between lines
`;

const PlaceholderText = styled.p`
  position: absolute; // Use absolute positioning
  top: -10px; // Move the text up by 10 pixels
  color: white;
  font-size: 12px; // Medium size
  text-align: center;
  width: 100%;
`;

const ArualPreFoot = () => {
  return (
    <PreFootContainer>
      <VideoContainer>
        <iframe 
          src="https://www.youtube.com/embed/Me51Er9xoYA" 
          title="YouTube video player" 
          frameBorder="0" 
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" 
          allowFullScreen
        ></iframe>
      </VideoContainer>
      <VideoTitle>Witness Wally The Wallet as he finds the Arual of power and takes revenge!</VideoTitle>
      <SpacerContainer /> {/* Top spacer container */}
      <PreFootContainerInt>
        <SubscriptionText>
          Arual Subscription Manager is available as a free trial and requires a monthly subscription of $4.99 to use beyond the trial period. Arual is a Google Chrome extension.
        </SubscriptionText>
      </PreFootContainerInt>
      <SpacerContainer>
        <PlaceholderText>
          Arual does not use or transmit any of your data to any third parties.<br />The purpose of Arual is designed 100% to provide the user with a meaningful tool that assists in managing subscriptions - That&apos;s all.
        </PlaceholderText>
      </SpacerContainer> {/* Bottom spacer container with placeholder text */}
    </PreFootContainer>
  );
};

export default ArualPreFoot;
