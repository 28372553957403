import styled from 'styled-components';

export const FlipbookListContainer = styled.div`
  background-color: black;
  padding: 0; /* Removed padding to eliminate gaps */
  margin: 0; /* Removed margin to eliminate gaps */
  border-radius: 0px;
  border-top: 1px solid #666;
`;

export const CoverImageContainer = styled.div`
  width: 100%;
  height: 700px; /* Explicit height */
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
  background-color: black; /* Optional: to match the container background */
  position: relative;

  img {
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    max-width: 100%;
    max-height: 100%;
  }
`;

export const FlipbookThumbnails = styled.div`
  display: flex;
  justify-content: center; /* Center the thumbnails */
  align-items: center;
  gap: 20px; /* Adds consistent spacing between thumbnails */
  max-width: 1200px; /* Limits the width of the container */
  margin: 0 auto; /* Center the container within the available space */
  flex-wrap: wrap; /* Allows thumbnails to wrap on smaller screens */
`;


export const Thumbnail = styled.img`
  width: 25%; /* Default size as a percentage of the container width */
  height: auto;
  cursor: pointer;
  border-radius: 10px;
  transition: transform 0.2s;

  &:hover {
    transform: scale(1.05);
  }

  @media (max-width: 1600px) {
    width: 30%; /* Slightly larger on smaller screens */
  }

  @media (max-width: 1200px) {
    width: 40%; /* Even larger on medium screens */
  }

  @media (max-width: 768px) {
    width: 80%; /* Full width on small screens */
  }
`;


export const Overlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

export const FlipbookModalContainer = styled.div`
  width: 1350px; /* Adjusted to fit within the viewport */
  height: 450px; /* Adjusted height */
  max-width: 100%;
  background-color: rgba(17, 17, 17); /* Background color */
  padding: 20px;
  border-radius: 10px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden; /* Ensures the dot pattern doesn't overflow the container */
`;

export const FlipbookPlaceholder = styled.div`
  width: 100%;
  height: 100%;
  background-color: #333;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  z-index: 2; /* Ensures the placeholder is above the dot pattern */
`;

export const CloseButton = styled.button`
  position: absolute;
  bottom: 20px;
  right: 20px;
  background-color: #ffcc00;
  color: #000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #e6b800;
  }
  z-index: 3; /* Ensures the close button is above everything */
`;

export const HeadlineBase = styled.a`
  display: flex;
  align-items: center;
  background-color: #ffcc00;
  width: 100%;
  height: 50px;
  text-decoration: none;
  color: white;
  padding-left: 60px;
  box-sizing: border-box;
  &:hover {
    background-color: #e6b800;
  }
`;

export const Logo = styled.img`
  height: 28px; /* Adjust as necessary */
  margin-right: 30px;
`;

export const HeadlineBaseText = styled.span`
  font-size: 22px;
  color: black;
  font-weight: bold;
`;

export const HighlightContainer = styled.div`
  background-color: black;
  border-radius: 20px;
  padding: 10px;
  color: #ffcc00;
  font-size: 18px;
  font-weight: bold;
  text-align: center;
  display: flex;
  align-items: center;
`;

export const SmallLogo = styled.img`
  height: 35px;
  margin-left: 15px;
`;
