import React, { useEffect, useState, useRef } from 'react';
import Slider from 'react-slick';
import styled from 'styled-components';
import { fetchArtworkImages } from '../api';
import "slick-carousel/slick/slick.css"; 
import "slick-carousel/slick/slick-theme.css";

const CarouselContainer = styled.div`
  .slick-slider {
    overflow: hidden;
  }

  .slick-track {
    display: flex;
  }

  .slick-slide {
    height: auto;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .slick-slide div {
    margin: 0; // Remove default margin
  }
`;

const BlackContainer = styled.div`
  background-color: rgba(0, 0, 0, 0);
  height: 60px; // Container height
  width: 100%; // Full width of the container
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 30px; // Add spacing above
`;

const NestedContainer = styled.div`
  background-color: #FFCC00; // Vibrant yellow background
  height: 40px; // Nested container height
  width: 70%; // Nested container takes up 90% of the width
  border-radius: 30px; // Rounded corners
  display: flex;
  justify-content: center;
  align-items: center;
`;

const NestedText = styled.p`
  color: black; // Text color
  font-size: 20px; // Text size
  font-weight: bold;
  margin: 0; // Remove default margin
`;

const ArtworkAboutContainer = styled.div`
  width: 100%; // Full width of the page
  height: auto; // Adjust height based on content
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px; // Add spacing above
  padding-bottom: 10px; // Add spacing below
`;

const AboutTextContainer = styled.div`
  padding: 40px; // Spacing around the text
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

const AboutText = styled.p`
  font-size: 20px; // Increased text size
  color: #fff; // White text color
  font-weight: bold; // More bold text
  text-align: center;
  margin: 0; // Remove default margin
  width: 60%; // Full width of the page
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 20px;
`;

const Button = styled.a`
  display: inline-block;
  padding: 10px 20px;
  background-color: #FFCC00; // Yellow background
  color: #000; // Black text
  border-radius: 20px; // Rounded corners
  text-decoration: none; // Remove underline
  font-weight: bold; // Bold text
  font-size: 16px; // Text size
  text-align: center;
  transition: transform 0.3s ease, background-color 0.3s ease; // Smooth transition for scaling and color change
  margin-left: 10px; // Add spacing between the buttons

  &:hover {
    transform: scale(1.1); // Slightly increase the size on hover
    background-color: #e6b800; // Slightly darker yellow on hover
  }

  &:first-child {
    margin-left: 0; // No margin on the first button
  }
`;

const ReelsContainer = styled.div`
  width: 100%;
  min-height: 450px; 
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 20px;
  gap: 20px; 
  flex-wrap: wrap; 
  overflow: hidden; 
`;

const VideoFrame = styled.div`
  width: 600px; // Lock the width to 600 pixels
  height: 336px; // Set the height to match the aspect ratio (16:9 for 600px width)
  position: relative;
  iframe {
    width: 100%;
    height: 100%;
    border: none; // Remove border
  }
`;



const Thumbnail = styled.div`
  width: 100px; // Normal width
  height: 200px; // Height remains constant
  background-image: url(${props => props.src.replace('http://', 'https://')});
  background-size: cover;
  background-position: center;
  transition: width 0.3s ease;
  cursor: pointer;

  &:hover {
    width: 200px; // Expanded width
  }
`;


const ModalOverlay = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.8);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
`;

const ModalContent = styled.div`
  max-width: 80vw;
  max-height: 80vh;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px; // Add padding for spacing around the image
  background-color: white; // White background for the modal content
  border-radius: 20px; // Add rounded corners to the modal content
`;

const ModalImage = styled.img`
  max-width: calc(80vw - 40px); // Ensure the image fits within the modal content with padding
  max-height: calc(80vh - 40px); // Ensure the image fits within the modal content with padding
  object-fit: contain;
  border-radius: 10px; // Add rounded corners to the image
`;

const NewBlackContainer = styled.div`
  width: 100%;
  background-color: rgba(0, 0, 0, 0);
  display: flex;
  justify-content: center;
  padding: 40px 0;
`;

const TextContainer = styled.div`
  width: 80%;
  background-color: rgba(0, 0, 0, 0);
  font-size: 18px;
  line-height: 1.6;
  margin-left: 16%; // Spacing between IMDb button and this container
`;

const Title = styled.h2`
  color: #FFCC00;
  font-weight: bold;
  margin-bottom: 20px;
`;

const ListItem = styled.p`
  margin-bottom: 10px;
  margin-top: 30px; // Spacing between IMDb button and this container
`;

const BackgroundWrapper = styled.div`
  width: 100%;
  position: relative;
  background-image: url('/artwork_BG_001.jpg');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  padding: 40px 0; /* Adjust as needed to create space around content */
`;

// New Styled Components for Skills
const SkillsContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  gap: 20px; /* Reduce the gap between sets */
  margin-top: 40px; /* Adjust spacing above the skills section */
`;

const SkillItem = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 10px; /* Adjust padding for more control over spacing */
`;

const SkillIcon = styled.i`
  font-size: 2rem; /* Adjust icon size */
  color: #FFCC00; /* Yellow color for the icons */
  margin-bottom: 10px; /* Space between icon and text */
`;

const SkillText = styled.p`
  font-size: 1rem; /* Text size */
  color: white; /* White text color */
  font-weight: bold;
  margin: 0; /* Remove default margin */
`;

// Footer Container
// Footer Container
const FooterContainer = styled.div`
  width: 50%;
  height: 60px;
  background-color: #FFCC00; // Yellow background
  border-radius: 30px; // Rounded corners
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  bottom: 0;
  margin: 10px auto; // Center horizontally within the parent
  transform: translateY(20px); // Adjust vertical alignment if needed
`;

const FooterText = styled.p`
  color: #000000; // Black text
  font-size: 24px; // Adjust text size as needed
  font-weight: bold;
  margin: 0; // Remove default margin
`;




const ArtworkCarousel = () => {
  const [images, setImages] = useState([]);
  const [selectedImage, setSelectedImage] = useState(null);
  const sliderRef = useRef(null);

  useEffect(() => {
    fetchArtworkImages().then(data => {
      const imagesNeeded = Math.ceil(window.innerWidth / 100 * 3); // Adjust based on thumbnail width and screen width
      const duplicatedImages = Array(Math.ceil(imagesNeeded / data.length)).fill([...data]).flat();
      setImages(duplicatedImages);
    });
  }, []);

  const settings = {
    infinite: true,
    speed: 2000, // Increased speed by 25%
    slidesToShow: Math.floor(window.innerWidth / 100), // Show as many thumbnails as fit in the window width
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0, // Making the autoplay speed effectively constant
    pauseOnHover: true,
    variableWidth: true,
    cssEase: 'linear',
    useTransform: false // Prevents jerky transitions
  };

  const handleMouseEnter = () => {
    sliderRef.current.slickPause();
  };

  const handleMouseLeave = () => {
    sliderRef.current.slickPlay();
  };

  const handleThumbnailClick = (image) => {
    setSelectedImage(image.highRes);
  };

  const handleCloseModal = () => {
    setSelectedImage(null);
  };

  return (
    <>
      <CarouselContainer>
        <BlackContainer>
          <NestedContainer>
            <NestedText>Creating digital artwork and animation since 2001 for feature film, TV and commercials.</NestedText>
          </NestedContainer>
        </BlackContainer>
  
        {/* Background Wrapper with the image */}
        <BackgroundWrapper>
          <ArtworkAboutContainer>
            <AboutTextContainer>
              <AboutText>
                David Edwards is an award-winning digital artist with over 20 years of experience in the entertainment industry. Specializing in CG environments and matte painting, he has contributed to numerous high-profile productions, including Wonder Woman, Blade Runner 2049, and Doctor Who. David excels in 3D, concept development, and motion graphics, delivering high-quality visual content for film, TV, and commercials. His passion for creativity and dedication to his craft make him a versatile and valued contributor to any project.
              </AboutText>
              <ButtonContainer>
                <Button href="https://www.imdb.com/name/nm3068138/" target="_blank">View IMDb Profile</Button>
                <Button href="https://www.artstation.com/everlite" target="_blank">View ArtStation Profile</Button>
                <Button href="https://www.linkedin.com/in/davidedwardsme/" target="_blank">View LinkedIn Profile</Button>
              </ButtonContainer>
            </AboutTextContainer>
          </ArtworkAboutContainer>
  
          <ReelsContainer>
            <VideoFrame>
              <iframe
                title="YouTube Video Player 1"
                src="https://www.youtube.com/embed/9u67Xb2pf7U"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </VideoFrame>
            <VideoFrame>
              <iframe
                title="YouTube Video Player 2"
                src="https://www.youtube.com/embed/mMvd8Dou9yg"
                frameBorder="0"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </VideoFrame>
          </ReelsContainer>
        </BackgroundWrapper>
  
        <Slider ref={sliderRef} {...settings}>
          {images.map((image, index) => (
            <div key={index} onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} onClick={() => handleThumbnailClick(image)}>
              <Thumbnail src={image.thumbnail} />
            </div>
          ))}
        </Slider>
        {selectedImage && (
          <ModalOverlay onClick={handleCloseModal}>
            <ModalContent onClick={(e) => e.stopPropagation()}>
              <ModalImage src={selectedImage} alt="Artwork" />
            </ModalContent>
          </ModalOverlay>
        )}
      </CarouselContainer>
  
      <NewBlackContainer>
        <TextContainer>
          <Title>What can I do for you?</Title>
          <ListItem>I can create animation, TV commercials, motion graphics, title sequences, visual effects for film, TV, and commercials.</ListItem>
          <ListItem>I can create digital set extensions, matte paintings, and environments from concept to delivery for film and TV.</ListItem>
          <ListItem>I can create high-quality illustrations for marketing materials such as movie posters, video game covers, book covers, and more.</ListItem>
          <ListItem>I can create programs, Chrome extensions, addons, plugins, web applications, and tools.</ListItem>
          <ListItem>I can create video games with a relatively small independent scope.</ListItem>
          <ListItem>Pretty much anything you can imagine, and maybe a few things you can&apos;t imagine!</ListItem>
          <ListItem>Below are a list of clients and projects that have entrusted my professional skills:</ListItem>
        </TextContainer>
      </NewBlackContainer>

      {/* Skills Section */}
      <SkillsContainer>
        <SkillItem>
          <SkillIcon className="fas fa-paint-brush"></SkillIcon>
          <SkillText>Matte Painting</SkillText>
        </SkillItem>
        <SkillItem>
          <SkillIcon className="fas fa-pencil-alt"></SkillIcon>
          <SkillText>Illustration</SkillText>
        </SkillItem>
        <SkillItem>
          <SkillIcon className="fas fa-film"></SkillIcon>
          <SkillText>Animation</SkillText>
        </SkillItem>
        <SkillItem>
          <SkillIcon className="fas fa-mountain"></SkillIcon>
          <SkillText>CG Environments</SkillText>
        </SkillItem>
        <SkillItem>
          <SkillIcon className="fas fa-tools"></SkillIcon>
          <SkillText>CG Generalist</SkillText>
        </SkillItem>
        <SkillItem>
          <SkillIcon className="fas fa-gamepad"></SkillIcon>
          <SkillText>Game Development</SkillText>
        </SkillItem>
        <SkillItem>
          <SkillIcon className="fas fa-code"></SkillIcon>
          <SkillText>Programming</SkillText>
        </SkillItem>
      </SkillsContainer>

      <FooterContainer>
        <FooterText>Get in touch! impossiblecreaturesmail(at)proton.me</FooterText>
      </FooterContainer>
    </>
    
  );
};

export default ArtworkCarousel;

