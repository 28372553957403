import React from 'react';
import { useFormik } from 'formik';
import { Helmet } from 'react-helmet';  
import * as Yup from 'yup';
import styled from 'styled-components';
import axios from 'axios';

const ContactContainer = styled.div`
  padding: 20px;
  max-width: 600px;
  margin: 100px auto 0 auto; /* Add top margin equal to navbar height plus some extra space */
  color: white;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
`;

const Input = styled.input`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #333333;
  border-radius: 5px;
  background-color: #1f1f1f;
  color: white;
`;

const TextArea = styled.textarea`
  margin-bottom: 10px;
  padding: 10px;
  border: 1px solid #333333;
  border-radius: 5px;
  background-color: #1f1f1f;
  color: white;
`;

const Button = styled.button`
  background-color: #ffcc00;
  color: #000000;
  border: none;
  padding: 10px 20px;
  border-radius: 5px;
  cursor: pointer;
  &:hover {
    background-color: #e6b800;
  }
`;

const EmailImage = styled.img`
  display: block;
  margin: 20px auto; /* Center the image and add spacing */
  width: 50%; /* Scale down the image to 50% of its original size */
`;

const Contact = () => {
  const formik = useFormik({
    initialValues: {
      name: '',
      email: '',
      message: '',
    },
    validationSchema: Yup.object({
      name: Yup.string().required('Required'),
      email: Yup.string().email('Invalid email address').required('Required'),
      message: Yup.string().required('Required'),
    }),
    onSubmit: async (values, { setSubmitting, resetForm }) => {
      try {
        console.log('Sending values:', values); // Log values being sent
        const response = await axios.post('https://www.impossiblecreatures.com/api/contact', values);
        console.log('Response received:', response.data); // Log response received
        alert('Message sent successfully');
        resetForm();
      } catch (error) {
        console.error('Error sending message:', error); // Log any errors
        alert('Failed to send message');
      } finally {
        setSubmitting(false);
      }
    },
  });

  return (
    <ContactContainer>

      <Helmet>
        <title>Impossible Creatures - Created by David Edwards</title>
        <meta name="description" content="Impossible Creatures is an incubator of ideas created by David Edwards." />
        <meta name="keywords" content="david edwards, creator, artist, vfx artist, contact, email" />
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content="Arual Subscription Manager Contact" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.impossiblecreatures.com/branding/SocialMedia/OG_1200x630_002.png" />
        <meta property="og:url" content="https://www.impossiblecreatures.com" />
        <meta property="og:description" content="Explore revolutionary AI-driven artwork and technological advancements with Impossible Creatures." />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@impossiblecrits" />
        <meta name="twitter:title" content="Impossible Creatures" />
        <meta name="twitter:description" content="Augmenting the human condition with AI exploration." />
        <meta name="twitter:image" content="https://www.impossiblecreatures.com/branding/SocialMedia/Twitter_1200x628_002.png" />
        <link rel="canonical" href="https://www.impossiblecreatures.com" />
      </Helmet>

      <h2>Contact Us</h2>
      <Form onSubmit={formik.handleSubmit}>
        <Input
          type="text"
          name="name"
          placeholder="Your Name"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.name}
        />
        {formik.touched.name && formik.errors.name ? <div>{formik.errors.name}</div> : null}
        <Input
          type="email"
          name="email"
          placeholder="Your Email"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.email}
        />
        {formik.touched.email && formik.errors.email ? <div>{formik.errors.email}</div> : null}
        <TextArea
          name="message"
          placeholder="Your Message"
          rows="5"
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.message}
        />
        {formik.touched.message && formik.errors.message ? <div>{formik.errors.message}</div> : null}
        <Button type="submit" disabled={formik.isSubmitting}>
          Send
        </Button>
      </Form>
      <EmailImage src="/email_001.png" alt="Contact Email" />
    </ContactContainer>
  );
};

export default Contact;
