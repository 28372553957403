import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';

const SidebarContainer = styled.div`
  position: absolute;
  top: 20px; /* Adjusted to start below the navbar (80px height) */
  left: ${({ isOpen }) => (isOpen ? '0' : '-300px')};
  width: 300px;
  height: calc(100% - 20px); /* Adjust height to account for the navbar */
  background: black;
  transition: left 0.3s ease;
  border-right: 0px solid grey;
  border-bottom: 1px solid grey;
`;

const NestedContainer = styled.div`
  width: calc(100% - 20px); /* Full width minus offset */
  height: calc(100% - 20px); /* Full height minus offset */
  background: black; /* Black background */
  border: 1px solid grey; /* 1 pixel line border */
  border-radius: 10px; /* 10 pixel radius */
  margin: 10px; /* Offset from the edge of the component */
`;

const SidePanel = ({ isOpen }) => {
  return (
    <SidebarContainer isOpen={isOpen}>
      <NestedContainer />
    </SidebarContainer>
  );
};

SidePanel.propTypes = {
  isOpen: PropTypes.bool.isRequired,
};

export default SidePanel;
