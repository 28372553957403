import React from 'react';
import styled from 'styled-components';

// Main container for the top section
const TopContainer = styled.div`
  background-image: url('/arual/layout_top.png');
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  height: 870px; // Adjusted height to ensure the bottom is visible
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;

  @media (min-width: 2000px) {
    height: 100vh; // Maintain full height on larger screens
  }
`;

// Container to hold the two horizontal cells
const HorizontalContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;
`;

// Left cell taking 30% of the width
const LeftCell = styled.div`
  width: 30%;
  display: flex;
  align-items: center;
`;

// Right cell taking 70% of the width
const RightCell = styled.div`
  width: 70%;
  display: flex;
  justify-content: flex-start; // Align content to the left side
  align-items: center;
`;

// Container for the text, positioned with padding
const TextContainer = styled.div`
  color: white;
  text-align: left;
  max-width: 70%; // Control the width within the right cell
  padding: 220px 0 0 100px; // Increased padding-top to move the text down
  box-sizing: border-box;

  @media (min-width: 2000px) {
    max-width: 70%;
    padding: 310px 0 0 200px; // Adjust padding for larger screens
  }
`;

// New yellow container with link text, positioned inside the text container
const YellowContainer = styled.a`
  background-color: #ffcc00;
  border-radius: 20px;
  padding: 15px 20px;
  margin-bottom: 20px; // Spacing between the yellow container and the text below
  text-align: center;
  max-width: 60%; // Reduced width of the yellow container
  display: inline-block;
  text-decoration: none;
  color: white;
  font-weight: bold;
  font-size: 18px;
  transition: transform 0.3s ease; // Smooth transition for hover effect

  &:hover {
    transform: scale(1.05); // Slightly increase size on hover
  }

  &:active,
  &:focus,
  &:hover {
    transform: scale(1.05); // Apply the same effect on focus and active states
  }

  &:mouseleave {
    transform: scale(1); // Reset to normal size on mouse leave
  }

  @media (min-width: 2000px) {
    max-width: 40%; // Slightly narrower on larger screens
    padding: 20px 30px; // Increase padding for larger screens
    font-size: 22px; // Increase font size on larger screens
  }
`;

// Styles for the main text
const HeadText001 = styled.div`
  font-size: 25px;
  line-height: 1.6;
  margin-bottom: 20px;

  @media (min-width: 2000px) {
    font-size: 32px; // Increase font size for larger screens
  }
`;

// Styles for the highlighted text
const HighlightText = styled.span`
  color: #ffaf02;
  font-weight: bold;
`;

const ArualTop = () => {
  return (
    <TopContainer>
      <HorizontalContainer>
        <LeftCell></LeftCell>
        <RightCell>
          <TextContainer>
            <YellowContainer
              href="https://chromewebstore.google.com/detail/arual-subscription-manage/ocdbodjojddglkaknpdmjnphpdjjbpfn?authuser=0&hl=en-GB&pli=1"
              target="_blank"
            >
              Download from Chrome Store now!
            </YellowContainer>
            <HeadText001>
              <HighlightText>74%</HighlightText> of people forget about recurring charges, and <HighlightText>42%</HighlightText> continue paying for services they no longer use.
            </HeadText001>
            <HeadText001>
              Many consumers think they spend around <HighlightText>$86</HighlightText> per month when the actual average is <HighlightText>$219.</HighlightText>
            </HeadText001>
            <HeadText001>
              <HighlightText>42%</HighlightText> of the average <HighlightText>$219</HighlightText> monthly expense means that individuals waste about <HighlightText>$91.98</HighlightText> monthly, totaling <HighlightText>$1,103.76!</HighlightText> annually on unused services.
            </HeadText001>
          </TextContainer>
        </RightCell>
      </HorizontalContainer>
    </TopContainer>
  );
};

export default ArualTop;
