import React from 'react';
import styled from 'styled-components';
import { Helmet } from 'react-helmet'; 
import ArualFooter from '../components/ArualFooter';
import ArualPreFoot from '../components/ArualPreFoot';
import ArualMid from '../components/ArualMid';
import ArualTop from '../components/ArualTop';
import ArualTopFlags from '../components/ArualTopFlags';

const ArualWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: stretch;
  margin-top: 100px; 
  padding: 0;
`;

const Arual = () => {
  return (
    <ArualWrapper>
      <Helmet>
        <title>Arual Subscription Manager - Manage Your Subscriptions Effectively</title>
        <meta name="description" content="Arual helps you manage and monitor your subscriptions seamlessly. Avoid unnecessary charges and keep track of your spending with Arual." />
        <meta name="keywords" content="subscription manager, subscription tracking, expense management, Arual, chrome extension" />
        <meta charset="utf-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta property="og:title" content="Arual Subscription Manager" />
        <meta property="og:type" content="website" />
        <meta property="og:image" content="https://www.impossiblecreatures.com/branding/SocialMedia/OG_1200x630_001.png" />
        <meta property="og:url" content="https://www.impossiblecreatures.com/arual" />
        <meta property="og:description" content="Manage your subscriptions with Arual. Efficiently track and control your monthly subscription services in one place." />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:creator" content="@impossiblecrits" />
        <meta name="twitter:title" content="Arual Subscription Manager" />
        <meta name="twitter:description" content="Avoid unnecessary charges and keep track of your subscriptions with Arual." />
        <meta name="twitter:image" content="https://www.impossiblecreatures.com/branding/SocialMedia/Twitter_1200x628_001.png" />
        <link rel="canonical" href="https://www.impossiblecreatures.com/arual" />
      </Helmet>
      <ArualTop />
      <ArualTopFlags />
      <ArualMid />
      <ArualPreFoot />
      <ArualFooter />
    </ArualWrapper>
  );
};

export default Arual;
