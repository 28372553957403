import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import styled from 'styled-components';

const ProjectContainer = styled.div`
  border: 0px solid white;
  border-radius: 20px;
  margin: 10px;
  padding: 20px;
  color: white;
  background-color: #1f1f1f;
  transition: transform 0.2s;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  &:hover {
    transform: scale(1.05);
  }
`;

const ImageContainer = styled.div`
  width: 100%; /* 90% of the parent container's width */
  height: 0;
  padding-top: 50.625%; /* Adjusted for slightly taller aspect ratio */
  background-color: #333;
  border-radius: 10px;
  position: relative;
  overflow: hidden;
  margin-bottom: 20px;
`;

const Image = styled.img`
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: auto;
  transform: translate(-50%, -50%);
  object-fit: cover;
`;

const Placeholder = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  color: white;
  font-size: 1.2em;
`;

const ProjectTitle = styled.h3`
  margin-top: 0;
`;

const ProjectDescription = styled.p`
  margin-bottom: 10px;
`;

const ProjectLink = styled(Link)`
  color: #ffcc00;
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
`;

const ProjectItem = ({ project }) => {
  return (
    <ProjectContainer>
      <ImageContainer>
        {project.image ? (
          <Image src={project.image} alt={project.title} />
        ) : (
          <Placeholder>No Image</Placeholder>
        )}
      </ImageContainer>
      <ProjectTitle>{project.title}</ProjectTitle>
      <ProjectDescription>{project.description}</ProjectDescription>
      <ProjectLink to={`/project/${project.id}`}>View Details</ProjectLink>
    </ProjectContainer>
  );
};

ProjectItem.propTypes = {
  project: PropTypes.shape({
    id: PropTypes.string.isRequired,
    image: PropTypes.string,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired,
  }).isRequired,
};

export default ProjectItem;
