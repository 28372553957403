import axios from 'axios';

const API_URL = 'https://impossiblecreatures.com/api';

export const fetchProjects = async () => {
  const response = await axios.get(`${API_URL}/projects`);
  return response.data;
};

export const fetchProjectById = async (id) => {
  const response = await axios.get(`${API_URL}/projects/${id}`);
  return response.data;
};

export const createProject = async (formData) => {
  const response = await axios.post(`${API_URL}/projects`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const updateProject = async (id, formData) => {
  const response = await axios.put(`${API_URL}/projects/${id}`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const deleteProject = async (id) => {
  await axios.delete(`${API_URL}/projects/${id}`);
};

export const fetchHeadline = async () => {
  const response = await axios.get(`${API_URL}/headline`);
  return response.data;
};

export const updateHeadline = async (formData) => {
  const response = await axios.put(`${API_URL}/headline`, formData, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
  return response.data;
};

export const fetchContactEmail = async () => {
  const response = await axios.get(`${API_URL}/contact-email`);
  return response.data.email;
};

export const updateContactEmail = async (email) => {
  const response = await axios.put(`${API_URL}/contact-email`, { email });
  return response.data;
};

// New function to fetch artwork images
export const fetchArtworkImages = async () => {
  try {
    const response = await axios.get(`${API_URL}/artwork`);
    return response.data.map(img => ({
      thumbnail: img.thumbnail,
      highRes: img.highRes
    }));
  } catch (error) {
    console.error('Error fetching artwork images:', error);
    return [];
  }
};
