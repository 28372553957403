import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { fetchHeadline } from '../api';

const HeadlineContainer = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  color: white;
  background-color: black;
  border-bottom: 0px solid #666666;
  border-left: 0px solid grey;
  border-right: 1px solid grey;
  width: 100%;
  flex-grow: 1;  // Ensure it fills available space
  box-sizing: border-box;  // Include padding and border in width and height
  transform: ${({ isPanelOpen }) => isPanelOpen ? 'translateX(300px)' : 'translateX(0)'};
  transition: transform 0.3s ease;
  background-image: url('/branding/duckdeer1.png');
  background-size: 60%;
  background-repeat: no-repeat;
  background-position: right -80px bottom -40px;
  min-height: 500px;
  z-index: 100;
`;

const TextContainer = styled.div`
  padding: 20px;
  box-sizing: border-box;
  margin-left: 80px; // Move the content to the right by 80 pixels
`;


const HeadlineTitle = styled.h1`
  font-size: 60px;
  color: #ffcc00;
  font-family: 'Montserrat', sans-serif !important;
`;

const HeadlineText = styled.p`
  font-size: 16px;
  color: #999999;
  padding: 10px;
`;

const ArrowButton = styled.button`
  position: absolute;
  top: 30px;  // Move the button down by 20 pixels
  left: 10px;
  background: #ffcc00;
  color: black;
  border: none;
  cursor: pointer;
  padding: 7px 3px;  // Reduce padding to 50%
  z-index: 200;
  font-size: 14px; // Reduce font size to 50%
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 25px; // Add border-radius to the button
`;

const HeadlineSection = ({ isPanelOpen, togglePanel }) => {
  const [headline, setHeadline] = useState({ title: '', text: '' });

  useEffect(() => {
    const getHeadline = async () => {
      const headlineData = await fetchHeadline();
      setHeadline(headlineData);
    };

    getHeadline();
  }, []);

  return (
    <HeadlineContainer isPanelOpen={isPanelOpen}>
      <ArrowButton onClick={togglePanel}>
        <i className={`fas fa-chevron-${isPanelOpen ? 'left' : 'right'}`}></i>
      </ArrowButton>
      <TextContainer>
        <HeadlineTitle>{headline.title || 'Welcome to My Portfolio'}</HeadlineTitle>
        <HeadlineText dangerouslySetInnerHTML={{ __html: headline.text || 'Here you will find my latest projects and works.' }} />
      </TextContainer>
    </HeadlineContainer>
  );
};

HeadlineSection.propTypes = {
  isPanelOpen: PropTypes.bool.isRequired,
  togglePanel: PropTypes.func.isRequired,
};

export default HeadlineSection;
